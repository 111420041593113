@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;500;600;700&display=swap");
:root {
  --white: #fff;
  --bg-base: #1b1b1f;
}
html {
  overflow: hidden;
}
body {
  font-family: "Inter" !important;
}

.ant-upload {
  width: 100%;
}
.react-pdf__Page__textContent {
  display: none;
}

.annotationLayer {
  display: none;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.avatar {
  background: linear-gradient(
      115.5deg,
      rgba(62, 64, 91, 0.4) 4.74%,
      rgba(29, 30, 43, 0.0001) 49.82%,
      rgba(29, 30, 43, 0.0001) 68.56%,
      #3e405b 115.85%
    ),
    linear-gradient(
      180deg,
      rgba(245, 250, 255, 0.08) 0%,
      rgba(244, 249, 255, 0.04) 100%
    );
}
.avatar img {
  object-fit: contain;
  border-radius: 8px;
}

.toast-warning {
  width: 412px;
  right: 50%;
  background: #1b1b1f;
  box-shadow:
    0px 8px 16px 0px rgba(0, 0, 0, 0.32),
    0px 0px 0px 1px rgba(255, 255, 255, 0.1);
  border: 1px solid #2e3035;
  border-radius: 8px;
  padding: 0;
}

.toast-warning .Toastify__toast-body {
  align-items: flex-start;
}
@layer components {
  .btn-collapse {
    @apply flex flex-row items-center w-full py-[22px] justify-center text-[13px]/[20px] text-greyED gap-[6px];
  }
  .footer {
    @apply flex-1 flex flex-row items-center justify-center gap-[6px] text-[13px] xl:text-sm text-greyED font-medium pt-[22px] pb-4 xl:py-[38px] px-4 cursor-pointer;
  }
  .footer img {
    @apply w-5 h-5;
  }
  .cv-content {
    @apply max-h-[calc(100dvh-312px)] md:max-h-[calc(100dvh-700px)] xl:max-h-[392px] overflow-hidden;
  }
  .queue-info .ant-tooltip-inner {
    @apply bg-base w-[368px] shadow-tooltip py-2 px-3 rounded-lg ml-6;
  }
}

@keyframes slideline {
  0% {
    top: 0%;
  }
  50% {
    top: 100%;
  }
  100% {
    top: 0%;
  }
}

@media (min-width: 768px) {
  .description {
    background: linear-gradient(
      121deg,
      #fff 85.35%,
      rgba(255, 255, 255, 0.16) 120.52%
    ) !important;
    background-clip: text !important;
  }
}
@media (max-width: 640px) {
  .title {
    font-size: 24px;
    line-height: 32px;
  }
  .description {
    font-size: 13px;
    line-height: 21px;
    background: linear-gradient(
      121deg,
      #fff 80.35%,
      rgba(255, 255, 255, 0.65) 102.52%
    ) !important;
    background-clip: text !important;
    letter-spacing: unset;
  }
  .toast-warning {
    width: unset;
    right: unset;
  }
}

.ant-upload-wrapper,
.ant-upload {
  height: 100%;
  display: block;
}

.ct_show_10_cv {
  display: flex;
  align-items: flex-end;
  overflow: hidden !important;
}

.ant-modal * {
  font-family: "Inter" !important;
}

.dark-linear-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
}

@media (max-width: 639px) {
  .mb_overlay_tooltip .ant-tooltip-content .ant-tooltip-inner {
    width: 100%;
    margin-left: -25px;
  }
}
